<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="state.params.region" />
        <filter-area
          class="mt-2 mr-2"
          v-model:value="state.params.area"
          v-model:area="state.params.area"
          v-model:region="state.params.region"
        />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:value="state.params.distributor"
          v-model:distributor="state.params.distributor"
        ></filter-distributor>
        <FilterToko
          class="mt-2 mr-2"
          v-model:value="state.params.toko"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-brands class="mr-2 mt-2" v-model:value="state.params.brand" v-model:brand="state.params.brand" />
        <AMonthPicker
          class="mr-2 mt-2"
          v-model:value="state.params.end_month"
          placeholder="Pilih Bulan"
          style="width : 300px"
          format="MMMM YYYY"
        />
        <!-- <AMonthPicker
          class="mr-2 mt-2"
          v-model:value="start_date"
          placeholder="Dari Bulan"
          style="width : 300px"
          :disabled-date="disabledStartDate"
          format="MMMM YYYY"
        />
        <AMonthPicker
          class="mr-2 mt-2"
          v-model:value="end_date"
          placeholder="Sampai Bulan"
          style="width : 300px"
          :disabled-date="disabledEndDate"
          format="MMMM YYYY"
        /> -->
        <a-button
          class="mr-2 mt-2"
          type="primary"
          title="cari"
          @click="btnSearch()"
          :loading="state.loading"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!state.loading"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>

    <div class="row justify-content-end mt-5">
        <div class="col-lg-12 col-md-12 mt-auto">
            <div class="card">
                <div class="card-body">
                    <GraphBarChart
                        :data="state.graph"
                        :loading="state.loadingGraph"
                        title="Diagram Toko Noo"/>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-3 mb-2"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive ">
              <md-table
                :columns="state.columns"
                :data-source="state.data"
                :row-selection="rowSelection"
                size="small"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: state.meta.total,
                  pageSize: state.meta.per_page,
                  current: state.meta.page,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                bordered
                @change="handleTableChange"
                :loading="state.loading"
              >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>

                <template #customer="{ text }">
                  <span v-if="searchText">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark
                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                  </template>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterToko from '@/components/filter/FilterToko'
import GraphBarChart from './GraphBarChart'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'
import { debounce, pickBy } from 'lodash'

export default defineComponent({
  name: 'VbDataTokoNoo',
  components: {
    // MModal,
    FilterRegional,
    // FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterBrands,
    // FilterProducts,
    // FilterKabupatenKota,
    FilterToko,
    GraphBarChart,
  },
  setup() {
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Transaksi',
          dataIndex: 'tanggal',
        },
        {
          title: 'Kode Toko',
          dataIndex: 'kode_toko',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'nama_toko',
        },
        {
          title: 'Alamat',
          dataIndex: 'wilayah',
        },
        {
          title: 'Area',
          dataIndex: 'area',
        },
        {
          title: 'Regional',
          dataIndex: 'regional',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'kode_distributor',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'nama_distributor',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'kode_produk',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'nama_produk',
        },
        {
          title: 'Brand',
          dataIndex: 'brand',
        },
        {
          title: 'Zak Quantity',
          dataIndex: 'qty',
        },
        {
          title: 'Ton Quantity',
          dataIndex: 'qty_ton',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
          title: 'Cluster',
          dataIndex: 'cluster_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
          title_xls: 'SSM',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
          title_xls: 'ASM',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
          title_xls: 'TSO',
        },
      ],
      data: [],
      graph: [],
      selectedRowKeys: [],
      loadingGraph: false,
      loading: false,
      isFetching: false,
      isDownloading: false,
      params: {
        q: '',
        region: [],
        area: [],
        brand: [],
        distributor: [],
        toko: [],
        // start_date: moment(new Date()).startOf('month'),
        // end_date: moment(new Date()),
        end_month: moment(new Date()),
        page: 1,
        'per-page': 10,
      },
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const searchText = ref('')
    const searchInput = ref(null)
    const errorMessage = ref(null)

    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
    } = useDisabledDate()

    start_date.value = moment(new Date()).startOf('month')
    end_date.value = moment(new Date())

    const queryParams = () => {
      let params = state.params

      if (state.params.end_month) {
        params.end_month = moment(state.params.end_month).format('YYYY-MM')
      }

      return pickBy(params)
    }

    const fetchData = () => {
      state.loading = true
      apiClient
        .get('api/report/customer-noo-v2', {
          params: queryParams(),
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          
          state.meta.page_count = _meta.pageCount
          state.meta.total = _meta.totalCount
          state.meta.per_page = _meta.perPage
          state.meta.page = _meta.currentPage
        })
        .finally(() => {
          state.loading = false
        })
    }

    const fetchDataGraph = () => {
      state.loadingGraph = true
      apiClient
        .get(`/api/report/diagram-customer-noo`, {
          params: queryParams(),
        })
        .then(({ data }) => {
          if (data.items) {
            state.graph = data.items
          }
        })
        .catch(error => console.error(error))
        .finally(() => {
          state.loadingGraph = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('api/report/customer-noo-v2', {
          params: {
            ...queryParams(),
            _export: 'xls',
            _columns: state.columns
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Data-toko-noo_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const startRow = computed(() => (state.params.page - 1) * state.params['per-page'] + 1)

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      startRow.value = (state.params.page - 1) * state.params['per-page'] + 1
      
      btnSearch()
    }

    const btnSearch = () => {
      fetchData()
      fetchDataGraph()
    }

    onMounted(() => {
      btnSearch()
    })

    return {
      searchText,
      searchInput,
      handleTableChange,
      startRow,
      fetchXlsx,
      btnSearch,
      state,
      start_date,
      end_date,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
